export const MAP_CHART_FETCH = "MAP_CHART_FETCH";
export const MAP_CHART_FETCH_PENDING = "MAP_CHART_FETCH_PENDING";
export const MAP_CHART_FETCH_FULFILLED = "MAP_CHART_FETCH_FULFILLED";
export const MAP_CHART_FETCH_REJECTED = "MAP_CHART_FETCH_REJECTED";

export const STATE_WISE_FETCH = "STATE_WISE_FETCH";
export const STATE_WISE_FETCH_PENDING = "STATE_WISE_FETCH_PENDING";
export const STATE_WISE_FETCH_FULFILLED = "STATE_WISE_FETCH_FULFILLED";
export const STATE_WISE_FETCH_REJECTED = "STATE_WISE_FETCH_REJECTED";

export const ALL_STATE_WISE_FETCH = "ALL_STATE_WISE_FETCH";
export const ALL_STATE_WISE_FETCH_PENDING = "ALL_STATE_WISE_FETCH_PENDING";
export const ALL_STATE_WISE_FETCH_FULFILLED = "ALL_STATE_WISE_FETCH_FULFILLED";
export const ALL_STATE_WISE_FETCH_REJECTED = "ALL_STATE_WISE_FETCH_REJECTED"; 

export const BLOCKDATA_FETCH = "BLOCKDATA_FETCH";
export const BLOCKDATA_FETCH_PENDING = "BLOCKDATA_FETCH_PENDING";
export const BLOCKDATA_FETCH_FULFILLED = "BLOCKDATA_FETCH_FULFILLED";
export const BLOCKDATA_FETCH_REJECTED = "BLOCKDATA_FETCH_REJECTED";
export const BLOCKDATA_FETCH_SOFT_DELETE = "BLOCKDATA_FETCH_SOFT_DELETE";

export const STATE_COLOR_CODE_FETCH = "STATE_COLOR_CODE_FETCH";
export const STATE_COLOR_CODE_FETCH_PENDING = "STATE_COLOR_CODE_FETCH_PENDING";
export const STATE_COLOR_CODE_FETCH_FULFILLED = "STATE_COLOR_CODE_FETCH_FULFILLED";
export const STATE_COLOR_CODE_FETCH_REJECTED = "STATE_COLOR_CODE_FETCH_REJECTED";

export const CURRENT_STATE_FETCH = "CURRENT_STATE_FETCH";
export const CURRENT_STATE_FETCH_PENDING = "CURRENT_STATE_FETCH_PENDING";
export const CURRENT_STATE_FETCH_FULFILLED = "CURRENT_STATE_FETCH_FULFILLED";
export const CURRENT_STATE_FETCH_REJECTED = "CURRENT_STATE_FETCH_REJECTED";

export const CURRENT_DISTRICT_FETCH = "CURRENT_DISTRICT_FETCH";
export const CURRENT_DISTRICT_FETCH_PENDING = "CURRENT_DISTRICT_FETCH_PENDING";
export const CURRENT_DISTRICT_FETCH_FULFILLED = "CURRENT_DISTRICT_FETCH_FULFILLED";
export const CURRENT_DISTRICT_FETCH_REJECTED = "CURRENT_DISTRICT_FETCH_REJECTED";

export const COMPARISON_DATA_FETCH = "COMPARISON_DATA_FETCH";
export const COMPARISON_DATA_FETCH_PENDING = "COMPARISON_DATA_FETCH_PENDING";
export const COMPARISON_DATA_FETCH_FULFILLED = "COMPARISON_DATA_FETCH_FULFILLED";
export const COMPARISON_DATA_FETCH_REJECTED = "COMPARISON_DATA_FETCH_REJECTED";

export const COMPARISON_CATEGORYWISE_DATA_FETCH = "COMPARISON_CATEGORYWISE_DATA_FETCH";
export const COMPARISON_CATEGORYWISE_DATA_FETCH_PENDING = "COMPARISON_CATEGORYWISE_DATA_FETCH_PENDING";
export const COMPARISON_CATEGORYWISE_DATA_FETCH_FULFILLED = "COMPARISON_CATEGORYWISE_DATA_FETCH_FULFILLED";
export const COMPARISON_CATEGORYWISE_DATA_FETCH_REJECTED = "COMPARISON_CATEGORYWISE_DATA_FETCH_REJECTED";

export const STATEWISE_CATEGORY_DATA_FETCH = "STATEWISE_CATEGORY_DATA_FETCH";
export const STATEWISE_CATEGORY_DATA_FETCH_PENDING = "STATEWISE_CATEGORY_DATA_FETCH_PENDING";
export const STATEWISE_CATEGORY_DATA_FETCH_FULFILLED = "STATEWISE_CATEGORY_DATA_FETCH_FULFILLED";
export const STATEWISE_CATEGORY_DATA_FETCH_REJECTED = "STATEWISE_CATEGORY_DATA_FETCH_REJECTED";

export const DISTRICT_CATEGORY_DATA_FETCH = "DISTRICT_CATEGORY_DATA_FETCH";
export const DISTRICT_CATEGORY_DATA_FETCH_PENDING = "DISTRICT_CATEGORY_DATA_FETCH_PENDING";
export const DISTRICT_CATEGORY_DATA_FETCH_FULFILLED = "DISTRICT_CATEGORY_DATA_FETCH_FULFILLED";
export const DISTRICT_CATEGORY_DATA_FETCH_REJECTED = "DISTRICT_CATEGORY_DATA_FETCH_REJECTED";

export const ALL_CATEGORY_FETCH = "ALL_CATEGORY_FETCH";
export const ALL_CATEGORY_FETCH_PENDING = "ALL_CATEGORY_FETCH_PENDING";
export const ALL_CATEGORY_FETCH_FULFILLED = "ALL_CATEGORY_FETCH_FULFILLED";
export const ALL_CATEGORY_FETCH_REJECTED = "ALL_CATEGORY_FETCH_REJECTED";

export const ALL_YEAR_FETCH = "ALL_YEAR_FETCH";
export const ALL_YEAR_FETCH_PENDING = "ALL_YEAR_FETCH_PENDING";
export const ALL_YEAR_FETCH_FULFILLED = "ALL_YEAR_FETCH_FULFILLED";
export const ALL_YEAR_FETCH_REJECTED = "ALL_YEAR_FETCH_REJECTED";

export const DPGI_SCORE_DATA_FETCH = "DPGI_SCORE_DATA_FETCH";
export const DPGI_SCORE_DATA_FETCH_PENDING = "DPGI_SCORE_DATA_FETCH_PENDING";
export const DPGI_SCORE_DATA_FETCH_FULFILLED = "DPGI_SCORE_DATA_FETCH_FULFILLED";
export const DPGI_SCORE_DATA_FETCH_REJECTED = "DPGI_SCORE_DATA_FETCH_REJECTED";

export const DISTRICT_UNDER_GRADE_DATA_FETCH = "DISTRICT_UNDER_GRADE_DATA_FETCH";
export const DISTRICT_UNDER_GRADE_DATA_FETCH_PENDING = "DISTRICT_UNDER_GRADE_DATA_FETCH_PENDING";
export const DISTRICT_UNDER_GRADE_DATA_FETCH_FULFILLED = "DISTRICT_UNDER_GRADE_DATA_FETCH_FULFILLED";
export const DISTRICT_UNDER_GRADE_DATA_FETCH_REJECTED = "DISTRICT_UNDER_GRADE_DATA_FETCH_REJECTED";

export const TOP_DISTRICT_CATEGORYWISE_DATA_FETCH = "TOP_DISTRICT_CATEGORYWISE_DATA_FETCH";
export const TOP_DISTRICT_CATEGORYWISE_DATA_FETCH_PENDING = "TOP_DISTRICT_CATEGORYWISE_DATA_FETCH_PENDING";
export const TOP_DISTRICT_CATEGORYWISE_DATA_FETCH_FULFILLED = "TOP_DISTRICT_CATEGORYWISE_DATA_FETCH_FULFILLED";
export const TOP_DISTRICT_CATEGORYWISE_DATA_FETCH_REJECTED = "TOP_DISTRICT_CATEGORYWISE_DATA_FETCH_REJECTED";

export const FILTER_WISE_SCHOOL_DATA_FETCH = "FILTER_WISE_SCHOOL_DATA_FETCH";
export const FILTER_WISE_SCHOOL_DATA_FETCH_PENDING = "FILTER_WISE_SCHOOL_DATA_FETCH_PENDING";
export const FILTER_WISE_SCHOOL_DATA_FETCH_FULFILLED = "FILTER_WISE_SCHOOL_DATA_FETCH_FULFILLED";
export const FILTER_WISE_SCHOOL_DATA_FETCH_REJECTED = "FILTER_WISE_SCHOOL_DATA_FETCH_REJECTED";

export const CARD_FILTER_DATA_FETCH = "CARD_FILTER_DATA_FETCH";
export const CARD_FILTER_DATA_FETCH_PENDING = "CARD_FILTER_DATA_FETCH_PENDING";
export const CARD_FILTER_DATA_FETCH_FULFILLED = "CARD_FILTER_DATA_FETCH_FULFILLED";
export const CARD_FILTER_DATA_FETCH_REJECTED = "CARD_FILTER_DATA_FETCH_REJECTED";

export const ALL_STATES_FETCH = "ALL_STATES_FETCH";
export const ALL_STATES_FETCH_PENDING = "ALL_STATES_FETCH_PENDING";
export const ALL_STATES_FETCH_FULFILLED = "ALL_STATES_FETCH_FULFILLED";
export const ALL_STATES_FETCH_REJECTED = "ALL_STATES_FETCH_REJECTED";

export const ALL_MAPSTATES_FETCH = "ALL_MAPSTATES_FETCH";
export const ALL_MAPSTATES_FETCH_PENDING = "ALL_MAPSTATES_FETCH_PENDING";
export const ALL_MAPSTATES_FETCH_FULFILLED = "ALL_MAPSTATES_FETCH_FULFILLED";
export const ALL_MAPSTATES_FETCH_REJECTED = "ALL_MAPSTATES_FETCH_REJECTED";

export const ALL_DISTRICT_FETCH = "ALL_DISTRICT_FETCH";
export const ALL_DISTRICT_FETCH_PENDING = "ALL_DISTRICT_FETCH_PENDING";
export const ALL_DISTRICT_FETCH_FULFILLED = "ALL_DISTRICT_FETCH_FULFILLED";
export const ALL_DISTRICT_FETCH_REJECTED = "ALL_DISTRICT_FETCH_REJECTED";

export const VISITOR_COUNTER_FETCH = "VISITOR_COUNTER_FETCH";
export const VISITOR_COUNTER_FETCH_PENDING = "VISITOR_COUNTER_FETCH_PENDING";
export const VISITOR_COUNTER_FETCH_FULFILLED = "VISITOR_COUNTER_FETCH_FULFILLED";
export const VISITOR_COUNTER_FETCH_REJECTED = "VISITOR_COUNTER_FETCH_REJECTED";

export const DISTRICT_LIST_GRADEWISE_FETCH = "DISTRICT_LIST_GRADEWISE_FETCH";
export const DISTRICT_LIST_GRADEWISE_FETCH_PENDING = "DISTRICT_LIST_GRADEWISE_FETCH_PENDING";
export const DISTRICT_LIST_GRADEWISE_FETCH_FULFILLED = "DISTRICT_LIST_GRADEWISE_FETCH_FULFILLED";
export const DISTRICT_LIST_GRADEWISE_FETCH_REJECTED = "DISTRICT_LIST_GRADEWISE_FETCH_REJECTED";

export const STATE_CATEGORYWISE_DATA_FETCH = "STATE_CATEGORYWISE_DATA_FETCH";
export const STATE_CATEGORYWISE_DATA_FETCH_PENDING = "STATE_CATEGORYWISE_DATA_FETCH_PENDING";
export const STATE_CATEGORYWISE_DATA_FETCH_FULFILLED = "STATE_CATEGORYWISE_DATA_FETCH_FULFILLED";
export const STATE_CATEGORYWISE_DATA_FETCH_REJECTED = "STATE_CATEGORYWISE_DATA_FETCH_REJECTED";

export const SCHOOL_DETAILS_DATA_FETCH = "SCHOOL_DETAILS_DATA_FETCH";
export const SCHOOL_DETAILS_DATA_FETCH_PENDING = "SCHOOL_DETAILS_DATA_FETCH_PENDING";
export const SCHOOL_DETAILS_DATA_FETCH_FULFILLED = "SCHOOL_DETAILS_DATA_FETCH_FULFILLED";
export const SCHOOL_DETAILS_DATA_FETCH_REJECTED = "SCHOOL_DETAILS_DATA_FETCH_REJECTED";


export const SQAF_LIST_DATA_FETCH = "SQAF_LIST_DATA_FETCH";
export const SQAF_LIST_DATA_FETCH_PENDING = "SQAF_LIST_DATA_FETCH_PENDING";
export const SQAF_LIST_DATA_FETCH_FULFILLED = "SQAF_LIST_DATA_FETCH_FULFILLED";
export const SQAF_LIST_DATA_FETCH_REJECTED = "SQAF_LIST_DATA_FETCH_REJECTED";


export const SQAF_DETAILS_DATA_FETCH = "SQAF_DETAILS_DATA_FETCH";
export const SQAF_DETAILS_DATA_FETCH_PENDING = "SQAF_DETAILS_DATA_FETCH_PENDING";
export const SQAF_DETAILS_DATA_FETCH_FULFILLED = "SQAF_DETAILS_DATA_FETCH_FULFILLED";
export const SQAF_DETAILS_DATA_FETCH_REJECTED = "SQAF_DETAILS_DATA_FETCH_REJECTED";


export const SQAF_FAQ_DATA_FETCH = "SQAF_FAQ_DATA_FETCH";
export const SQAF_FAQ_DATA_FETCH_PENDING = "SQAF_FAQ_DATA_FETCH_PENDING";
export const SQAF_FAQ_DATA_FETCH_FULFILLED = "SQAF_FAQ_DATA_FETCH_FULFILLED";
export const SQAF_FAQ_DATA_FETCH_REJECTED = "SQAF_FAQ_DATA_FETCH_REJECTED";

export const ALL_STATEGALLERY_FETCH = "ALL_STATEGALLERY_FETCH";
export const ALL_STATEGALLERY_FETCH_PENDING = "ALL_STATEGALLERY_FETCH_PENDING";
export const ALL_STATEGALLERY_FETCH_FULFILLED = "ALL_STATEGALLERY_FETCH_FULFILLED";
export const ALL_STATEGALLERY_FETCH_REJECTED = "ALL_STATEGALLERY_FETCH_REJECTED";


export const CARD_DASHBOARD_FILTER_DATA_FETCH = "CARD_DASHBOARD_FILTER_DATA_FETCH";
export const CARD_DASHBOARD_FILTER_DATA_FETCH_PENDING = "CARD_DASHBOARD_FILTER_DATA_FETCH_PENDING";
export const CARD_DASHBOARD_FILTER_DATA_FETCH_FULFILLED = "CARD_DASHBOARD_FILTER_DATA_FETCH_FULFILLED";
export const CARD_DASHBOARD_FILTER_DATA_FETCH_REJECTED = "CARD_DASHBOARD_FILTER_DATA_FETCH_REJECTED";
