import React from 'react';
// import './Methodology.scss';
import './about.css';
import 'bootstrap/dist/css/bootstrap.css';

function Aboutdetails() {
  return (
    <>
      <section className="banner-wrap banner-bg banner-state ptb-30 d-flex align-items-center" style={{ height:"160px"}}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="heading-blue text-center text-white pb-0">About PM SHRI</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutpgi ptb-30 pt-4">
        
        <div className="container">
        
              <div className="row">
               <div className="col-md-12">
                  <p className="desc-black">
                      As we continue to build an education system for the future, it is critical that the school
                      ecosystem is resilient in the face of an increasingly unpredictable, dynamic and non-linear
                      world. The PM SHRI schools are being designed to achieve that. These schools will help
                      showcase the implementation of the National Education Policy 2020 and emerge as
                      exemplar schools. They will provide leadership in their respective regions in providing highquality education in an equitable, inclusive and joyful school environment that takes care of
                      the diverse background, multilingual needs, and different academic abilities of children and
                      makes them active participants in their learning process. Schools are incentivized to yield
                      results and perform better through a funding mechanism that is tracked through quality
                      parameters. A decentralised administration strategy will be followed to ensure the effective
                      implementation of the scheme. A system of transparency and accountability will be created
                      for the effective delivery of performance at school levels.
                  </p>
                  <p className="desc-black">
                    These schools will focus on not only enhancing cognitive development but also creating
                    holistic and well-rounded individuals equipped with key 21st-century skills. The pedagogy
                    adopted in these schools will be more experiential, holistic, integrated, play/toy-based
                    (particularly in the foundational years), inquiry-driven, discovery-oriented, learner-centred,
                    discussion-based, flexible, and enjoyable. The focus will be on the learning outcomes of every
                    child in every grade. Assessment at all levels will be based on conceptual understanding and
                    application of knowledge to real-life situations and will be competency-based.
                  </p>
                  <p className="desc-black">
                    The dignity of the school as an institution would be restored, and important dates, such as the
                    school's foundation day, will be celebrated along with the community and the list of important
                    alumni may be displayed and honoured. Furthermore, the unutilized capacity of school
                    infrastructure would be used to promote social, intellectual, and volunteer activities for the
                    community and to promote social cohesion during non-teaching / schooling hours and may be
                    used as a “Samajik Chetna Kendra”. Suitable facilitating systems shall be put in place to
                    achieve universal participation in school by carefully tracking students, as well as their learning
                    levels, to ensure that they are enrolled in and attending school and have suitable opportunities
                    to catch up and re-enter school in case they have fallen behind or dropped out.
                  </p>
                 
               </div>
              </div>
            </div>
        
      </section>
    </>
  )
}

export default Aboutdetails;