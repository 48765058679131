import IndicatorsBanner from 'src/components/Indicators/IndicatorsBanner/IndicatorsBanner';
import IndicatorsCategory from 'src/components/Indicators/IndicatorsCategory/IndicatorsCategory';

const Indicators = () => {
  return (
    <>
      <IndicatorsBanner />
      <IndicatorsCategory />
    </>
  )
}

export default Indicators